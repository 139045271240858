import React from "react";
import LoginButton from "../button/LoginButton";

const PublicPage = () => {
  return (
    <div>
      <h2> Đây là trang public </h2>
      <LoginButton />
    </div>
  );
};

export default PublicPage;
