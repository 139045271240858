import { Route, BrowserRouter } from "react-router-dom";
import PrivatePage from "./components/pages/PrivatePage";
import PublicPage from "./components/pages/PublicPage";
import PrivateRouter from "./routers/PrivateRouter";
import PublicRouter from "./routers/PublicRouter";
import "./App.css";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <PrivateRouter path="/private" component={PrivatePage} />
        <PublicRouter path="/" component={PublicPage} />
      </BrowserRouter>
    </div>
  );
}

export default App;
