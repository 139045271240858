import React from "react";
import LogoutButton from "../button/LogoutButton";

const PrivatePage = () => {
  return (
    <div>
      <h2> Đây là trang private </h2>
      <LogoutButton />
    </div>
  );
};

export default PrivatePage;
